import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_FILTERS
    FETCH_FILTERS_SUCCESS
    FETCH_FILTERS_FAILURE
    UPDATE_SSR_STATUS

    UPDATE_FILTER_TIMESTAMP

    UPDATE_APPLIED_FILTERS
    UPDATE_SELECTED_ON_SCREEN_FILTER
    UPDATE_FILTER_OPTION_DATA
    UPDATE_ALL_FILTERS_VISIBILITY
    CLEAR_SELECTED_FILTERS
    FETCH_LISTING_COUNT
    FETCH_LISTING_COUNT_SUCCESS
    FETCH_LISTING_COUNT_FAILURE

    FETCH_TRENDING_SEARCH_INIT
    FETCH_TRENDING_SEARCH_SUCCESS
    FETCH_TRENDING_SEARCH_FAILURE

    SET_IS_RETURNING_USER
    SET_SEARCH_RESULT_TRACKING_DATA

    FETCH_RECOMMENDED_CARS_INIT
    FETCH_RECOMMENDED_CARS_SUCCESS
    FETCH_RECOMMENDED_CARS_FAILURE

    UPDATE_RECENTLY_VIEWED_SSR_STATUS
    FETCH_RECENTLY_VIEWED_CARS_INIT
    FETCH_RECENTLY_VIEWED_CARS_SUCCESS
    FETCH_RECENTLY_VIEWED_CARS_FAILURE

    FETCH_RECENTLY_ADDED_CARS_INIT
    FETCH_RECENTLY_ADDED_CARS_SUCCESS
    FETCH_RECENTLY_ADDED_CARS_FAILURE

    FETCH_POPULAR_CARS_INIT
    FETCH_POPULAR_CARS_SUCCESS
    FETCH_POPULAR_CARS_FAILURE

    FETCH_CUSTOM_RECOMMENDED_CARS_INIT
    FETCH_CUSTOM_RECOMMENDED_CARS_SUCCESS
    FETCH_CUSTOM_RECOMMENDED_CARS_FAILURE

    UPDATE_FILTER_TAB_TYPE
    UPDATE_SELECTED_SORT
    CLEAR_ALL_FILTERS

    UPDATE_APPLIED_FILTERS
    UPDATE_URL_OPTIONS

    UPDATE_APPLIED_SUGGESTIONS
    UPDATE_SELECTED_SUGGESTIONS

    CLEAR_SUGGESTIONS
    UPDATE_ALL_SUB_FILTER_OPTIONS

    UPDATE_SSR_FILTERS_LIST

    UPDATE_CUSTOM_VALUE

    NOTIFY_ON_NO_CAR_FOUND

    LISTING_NUDGE_GLOW
    UPDATE_SORT_MODAL_VISIBILITY
`,
    {
        prefix: "filters/"
    }
);
