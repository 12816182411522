import ordinalInWord from "./ordinal-in-word";
// import { shortMonthName } from "./get-month-by-number";
import makePriceLabel from "./make-price-label";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import dashedLowercase from "./dashed-lowercase";
import { makeOdometerLabelAE } from "./make-odometer-label";
import { ASSORTMENT_CATEGORY_LABEL, CAR_STATUS, LOAN_TYPE, NUMBER } from "../../constants/app-constants";
import { getBatteryCapacity } from "./get-battery-capacity";

const getCarName = (details = {}) => {
    const { year = "", make = "", model = "" } = details || {};
    return `${year} ${make} ${model}`;
};

const getCarNameWithoutYear = (details = {}) => {
    const { make, model } = details;
    return `${make} ${model}`;
};

const getCarKilometersDrivenLabel = (details) => details.odometerReading && makeOdometerLabelAE(details.odometerReading);
const getCarOwnersLabel = (details) => details.ownerNumber && `${ordinalInWord(details.ownerNumber)}`;
const getCarPriceLabel = (details, key = "price", currency = "AED") => makePriceLabel(details[key], currency);
const getCarPriceLabelWithoutCurrency = (details, key = "price", currency = "AED") => makePriceLabel(details[key], currency, false);
const getDownpayentLabel = (details, currency = "AED") => makePriceLabel(((details || {}).emiDetails || {}).downPayment, currency);
const getEmiLabel = (details, currency = "AED") => makePriceLabel(((details || {}).emiDetails || {}).emi, currency);
const getEmiLabelWithoutCurrency = (details, currency = "AED") => makePriceLabel(((details || {}).emiDetails || {}).emi, currency);
const getPurchaseDateBadge = (details) => {
    try {
        // eslint-disable-next-line no-magic-numbers
        const diff = Math.floor((new Date() - new Date(details.purchaseDate)) / (1000 * 60 * 60 * 24));
        const diffMap = {
            "-1": "Added Today",
            0: "Added Today",
            1: "Added Yesterday"
        };
        return diffMap[diff] ? diffMap[diff] : `Added ${formatDistanceToNowStrict(new Date(details.purchaseDate))} ago`;
    } catch (e) {
        return "";
    }
};

const TRIM_TYPES = {
    "fabric": "fabric",
    "leather": "leather",
    "partial-leather": "partialLeather"
};

const checkCarStatus = (car = {}, checkStatus) => {
    switch (checkStatus) {
        case CAR_STATUS.SOLD: {
            return !car?.listingActive;
        }
        case CAR_STATUS.RESERVED: {
            return car?.booked && car?.listingActive;
        }
        default: return false;
    }
};

const getShortKmLabel = (odometerReading = NUMBER.ZERO) => {

    if (odometerReading <= NUMBER.TEN) {
        return makeOdometerLabelAE(odometerReading);
    }
    if (odometerReading <= NUMBER.HUNDRED) {
        if (odometerReading % NUMBER.TEN !== NUMBER.ZERO) {
            odometerReading = parseInt(odometerReading / NUMBER.TEN) * NUMBER.TEN;
        }
        return makeOdometerLabelAE(odometerReading);
    } else if (odometerReading <= NUMBER.THOUSAND) {
        if (odometerReading % NUMBER.HUNDRED !== NUMBER.ZERO) {
            odometerReading = parseInt(odometerReading / NUMBER.HUNDRED) * NUMBER.HUNDRED;
        }
        return makeOdometerLabelAE(odometerReading);
    } else {
        return makeOdometerLabelAE(odometerReading);
    }
};

const greenCarPropertiesAe = (details) => {
    const carDetails = {...details};
    const greenCarDetails = ["batterycapacityinkwh", "batterychargingtime", "drivingrange", "electricmotorpowerinkw"];
    for (const i of details?.basicDetails) {
        if (greenCarDetails.includes(i?.key)) {
            carDetails[i?.key] = i?.value;
        }
    }
    const kilometersDriven = carDetails.odometerReading ? getCarKilometersDrivenLabel(carDetails) : "0 KM";
    return [
        { title: "Kilometers", value: kilometersDriven, type: "kilometer" },
        { title: "Battery capacity", value: getBatteryCapacity(carDetails?.batterycapacityinkwh, false), type: "batterycapacityinkwh" },
        { title: "Charging time", value: carDetails?.batterychargingtime, type: "batterychargingtime" },
        { title: "Battery range", value: carDetails?.drivingrange, type: "drivingrange" },
        { title: "Specs", value: carDetails.specs ? carDetails.specs : "NON GCC", type: "gcc" },
        { title: "Motor Power", value: carDetails?.electricmotorpowerinkw, type: "electricmotorpowerinkw" }
    ];
};

const carProprtiesAe = (details) => {
    // const purchaseDate = new Date(details.purchaseDate);
    const kilometersDriven = getCarKilometersDrivenLabel(details);
    // const buildYear = `${shortMonthName(purchaseDate.getMonth() + 1)} ${(purchaseDate.getFullYear())}`;
    if (details?.assortmentCategory === ASSORTMENT_CATEGORY_LABEL.GREEN) {
        return greenCarPropertiesAe(details);
    }
    return [
        { title: "Kilometers", value: kilometersDriven, type: "kilometer" },
        { title: "Transmission", value: details.transmissionType, type: "transmission" },
        { title: "Specs", value: details.specs, type: details.specs === "GCC" ? "gcc" : "nongcc" },
        { title: "Drive Type", value: details.driveType, type: details.driveType === "Four Wheel Drive" ? "4wd" : "2wd" },
        { title: "Fuel Type", value: details.fuelType, type: "fuel" },
        { title: "Trim", value: details.interiorTrimType, type: TRIM_TYPES[dashedLowercase(details.interiorTrimType)] }
        // { title: "Purchased", value: details.year, type: "buildyear" },

        // { title: "Body Type",
        //     value: details.bodyType,
        //     type: "bodytype"
        // }
    ];
};

export const imageAltTagLabel = (carDetail) => (
    `Used ${carDetail?.make} ${carDetail?.model} ${carDetail?.year} ${carDetail?.variant} ${carDetail?.transmissionType}, ${makeOdometerLabelAE(carDetail?.odometerReading)}, ${carDetail?.fuelType} Car`
);

const getLoanTypeLabelListing = (financeEligibility = "", downPaymentPercentage = NUMBER.ZERO, isElectricCar = false) => {
    let loanTypeLabel = "";
    if (financeEligibility === LOAN_TYPE.PERSONAL_LOAN) {
        loanTypeLabel = "Only Personal Loan";
    } else if (financeEligibility === LOAN_TYPE.CUSTOM_DP) {
        loanTypeLabel = `Auto Loan, ${downPaymentPercentage}% Downpayment`;
    } else if (!isElectricCar) {
        loanTypeLabel = "Auto Loan Zero Downpayment";
    } else if (financeEligibility === LOAN_TYPE.GREEN_CUSTOM_DOWN_PAYMENT) {
        loanTypeLabel = `Green Loan, ${downPaymentPercentage}% Downpayment`;
    } else if (financeEligibility === LOAN_TYPE.GREEN_ZERO_DOWN_PAYMENT) {
        loanTypeLabel = `Green Loan Zero Downpayment`;
    }
    return loanTypeLabel;
};

const getLoanTypeLabelListingV2 = (financeEligibility = "", downPaymentPercentage = NUMBER.ZERO) => {
    let loanTypeLabel = "";
    if (financeEligibility === LOAN_TYPE.PERSONAL_LOAN) {
        loanTypeLabel = "only personal loan";
    } else {
        loanTypeLabel = `${downPaymentPercentage}% downpay`;
    }
    return loanTypeLabel;
};

export default carProprtiesAe;
export {
    getCarName,
    getCarNameWithoutYear,
    getCarKilometersDrivenLabel,
    getCarOwnersLabel,
    getCarPriceLabel,
    getCarPriceLabelWithoutCurrency,
    getDownpayentLabel,
    getEmiLabel,
    getPurchaseDateBadge,
    checkCarStatus,
    getShortKmLabel,
    getLoanTypeLabelListing,
    getLoanTypeLabelListingV2,
    getEmiLabelWithoutCurrency
};
