/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Components
import RecentlyCarSectionSlider from "../recently-car-section-slider";
const Skeleton = loadable(() => import("./skeleton"));
import ArrowIcon from "../../shared/arrow";

// Constants
import { COOKIE_CONSTANTS, HOME_CAR_SECTION, NUMBER, SORT_DATA } from "../../../constants/app-constants";
import { defaultParams, defaultFilterPayload } from "../car-listing/constants";

// Utils
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import Filters from "../../../utils/filters-v2";

// Tracking
import { trackMobileCustomEventsAE } from "../../../tracking";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { homeCarSections } from "./constansts";

const HomeCarSection = ({
    sectionName = HOME_CAR_SECTION.RECOMMENDED,
    selectedCityName,
    selectedCityCode,
    hasCitySkip: citySkip,
    recommendedCars = [],
    recentlyViewedCars = [],
    recentlyAddedCars = [],
    isRecommendedCarsLoading,
    isRecentlyViewedCarsLoading,
    isRecentlyAddedCarsLoading,
    fetchRecommendedCarsConnect,
    fetchRecentlyViewedCarsConnect,
    fetchRecentlyAddedCarsConnect,
    clearAllFiltersConnect,
    isRecentlyViewedSSR,
    updateRecentlyViewedSSRStatusConnect
}) => {

    const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
    const recentlyViewedCarsData = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]")?.slice(NUMBER.ZERO, NUMBER.FIVE) || [];
    const isPopupShown = parseCookie(COOKIE_CONSTANTS.LOCATION_POPUP_SHOWN) || false;
    const hasCitySkip = !isPopupShown || citySkip;
    const { customPush } = useCustomHistoryOperations();
    // RECOMMENDED section actions
    const fetchRecommendedCars = () => {
        const params = {
            ...defaultParams,
            variant: "filterV5"
        };
        const { payload } = filters.getListingPayload({ ...defaultFilterPayload, hasCitySkip });
        fetchRecommendedCarsConnect(params, payload)
            // eslint-disable-next-line no-unused-vars
            .catch((error) => { });
    };

    const viewAllRecommended = async () => {
        clearAllFiltersConnect();
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, isGlobalUrl: true, hasCitySkip });
        await customPush(relativeURL);
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "recommended" });
        scrollToTop();
    };

    const handleRecommendedCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        await customPush(relativeURL);
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "recommended", appointment_id: carId });
    };

    const onSwipeCallBackRecommended = async ({appId}) => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "recommended", appointment_id: appId });
    };

    // RECENTLY_ADDED section actions
    const fetchRecentlyAddedCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sort: SORT_DATA.RECENTLY_ADDED.key }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5"
        };
        const { payload } = filters.getListingPayload({ ...filterPayload, hasCitySkip });
        fetchRecentlyAddedCarsConnect(params, payload)
            // eslint-disable-next-line no-unused-vars
            .catch((error) => { });
    };

    const viewAllNewlyAdded = async () => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "newly_added" });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, options: { sort: SORT_DATA.RECENTLY_ADDED.key }, isGlobalUrl: true, hasCitySkip });
        await customPush(relativeURL);
        scrollToTop();
    };

    const handleNewlyAddedCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "newly_added", appointment_id: carId });
        await customPush(relativeURL);
    };

    const onSwipeCallBackNewlyAdded = async () => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "newly_added" });
    };

    // RECENTLY_VIEWED section actions
    const fetchRecentlyViewedCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsData?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: recentlyViewedCarsData.length
        };
        const { payload } = filters.getListingPayload({ ...filterPayload, hasCitySkip });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            // Handle edge case where recently viewed cars might get de-listed
            if (total < recentlyViewedCarsData?.length) {
                updateRecentlyViewedCookie(results);
            }
            // eslint-disable-next-line no-unused-vars
        }).catch((error) => { });
    };

    const viewAllRecentlyViewed = async () => {
        clearAllFiltersConnect();
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, isGlobalUrl: true, hasCitySkip });
        await customPush(`${relativeURL}&isRecentlyViewed=true`);
        trackMobileCustomEventsAE("homePageViewAll", { eventLabel: "recent" });
        scrollToTop();
    };

    const handleRecentlyViewedCarClick = async ({relativeURL, carId}) => {
        clearAllFiltersConnect();
        await customPush(relativeURL);
        trackMobileCustomEventsAE("homePageCarClick", { eventLabel: "recent", appointment_id: carId });
    };

    const onSwipeCallBackRecentlyViewed = async ({appId}) => {
        trackMobileCustomEventsAE("homePageCarSwipe", { eventLabel: "recent", appointment_id: appId });
    };

    useEffect(() => {
        if (sectionName === HOME_CAR_SECTION.RECOMMENDED) {
            fetchRecommendedCars();
        }

        if (sectionName === HOME_CAR_SECTION.RECENTLY_ADDED) {
            if (!recentlyAddedCars?.length) {
                fetchRecentlyAddedCars();
            }
        }

        if (sectionName === HOME_CAR_SECTION.RECENTLY_VIEWED && !isRecentlyViewedSSR) {
            fetchRecentlyViewedCars();
        }
        updateRecentlyViewedSSRStatusConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionName]);

    return (
        <div styleName={"styles.homeCarSection"}>
            {sectionName === HOME_CAR_SECTION.RECOMMENDED && (
                <Fragment>
                    {isRecommendedCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!recommendedCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Recommended cars
                                    <span styleName={"styles.viewAll"} onClick={viewAllRecommended}>
                                        View All Cars
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSlider
                                    carList={recommendedCars}
                                    handleCarClick={handleRecommendedCarClick}
                                    onSwipeCallBack={onSwipeCallBackRecommended}
                                    sectionName={homeCarSections.RECOMMENDED_CARS}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}

            {sectionName === HOME_CAR_SECTION.RECENTLY_ADDED && (
                <Fragment>
                    {isRecentlyAddedCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!recentlyAddedCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Newly added cars in UAE
                                    <span styleName={"styles.viewAll"} onClick={viewAllNewlyAdded}>
                                        VIEW ALL CARS
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSlider
                                    carList={recentlyAddedCars}
                                    handleCarClick={handleNewlyAddedCarClick}
                                    onSwipeCallBack={onSwipeCallBackNewlyAdded}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}

            {sectionName === HOME_CAR_SECTION.RECENTLY_VIEWED && (
                <Fragment>
                    {isRecentlyViewedCarsLoading ?
                        <div styleName={"styles.carSliderSection"}>
                            <Skeleton />
                        </div> :
                        !!recentlyViewedCars?.length && (
                            <div styleName={"styles.carSliderSection"}>
                                <h3>Recently viewed cars
                                    <span styleName={"styles.viewAll"} onClick={viewAllRecentlyViewed}>
                                        View All Cars
                                        <span styleName={"styles.iconWrap"}>
                                            <ArrowIcon darkblue rotateBy={NUMBER.TWO_HUNDRED_SEVENTY} />
                                        </span>
                                    </span>
                                </h3>
                                <RecentlyCarSectionSlider
                                    carList={recentlyViewedCars}
                                    handleCarClick={handleRecentlyViewedCarClick}
                                    onSwipeCallBack={onSwipeCallBackRecentlyViewed}
                                    preloadNumber={NUMBER.TWO}
                                    sectionName={homeCarSections.RECENTLY_VIEWED_CARS}
                                />
                            </div>
                        )
                    }
                </Fragment>
            )}
        </div>
    );
};

HomeCarSection.propTypes = {
    sectionName: PropTypes.string,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    hasCitySkip: PropTypes.bool,
    recommendedCars: PropTypes.array,
    isRecommendedCarsLoading: PropTypes.bool,
    recentlyViewedCars: PropTypes.array,
    isRecentlyViewedCarsLoading: PropTypes.bool,
    recentlyAddedCars: PropTypes.array,
    isRecentlyAddedCarsLoading: PropTypes.bool,
    fetchRecommendedCarsConnect: PropTypes.func,
    fetchRecentlyViewedCarsConnect: PropTypes.func,
    fetchRecentlyAddedCarsConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    isRecentlyViewedSSR: PropTypes.bool,
    updateRecentlyViewedSSRStatusConnect: PropTypes.func
};

export default HomeCarSection;
