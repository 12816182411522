export const uniqueStack = (array, size) => {
    const items = [...array];
    return function (item) {
        if (items.indexOf(item) === -1) items.unshift(item);
        else items.splice(0, 0, ...items.splice(items.indexOf(item), 1));
        return items.slice(0, size);
    };
};

// const stack = uniqueStack([1,2,3,4,5], 5);
// console.log(stack(6));
// console.log(stack(3));
// console.log(stack(5));
// console.log(stack(1));
// console.log(stack(1));
// console.log(stack(1));
// console.log(stack(3));
