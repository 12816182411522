import { CURRENCY } from "../../constants/app-constants";
import priceFormatter from "./price-formatter";

const makePriceLabel = (price, currency = CURRENCY.AE, withCurrency = true) => `${withCurrency ? `${currency} ` : ""}${priceFormatter(price, true, currency)}`;

export const makePriceLabelRoundAE = (price,  currency = CURRENCY.AE) => {
    if (price === null || price === undefined || isNaN(price)) return "";

    if (currency === true) {
        currency = CURRENCY.AE;
    } else if (currency === false) {
        currency = "";
    }

    return makePriceLabel(Math.round(price), currency).trim();
};

export default makePriceLabel;
