import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import RecentlyCarSectionSliderLanding from "./component";

// Actions
import { showWishlistNudge } from "../my-wishlist/actions";

const mapStateToProps = () => ({ });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showWishlistNudgeConnect: showWishlistNudge
 }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentlyCarSectionSliderLanding));
