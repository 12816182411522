import { NUMBER, COOKIE_CONSTANTS } from "../../constants/app-constants";
import { addToRecentlyViewed, getRecentlyViewed } from "./recently-viewed";

export default (cars, isSlicedAppIds = true) => {
    const recentlyViewedCars = getRecentlyViewed(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
    const rvAppointmentIds = [];
    (cars || []).forEach(car => {
        if (car?.appointmentId) {
            rvAppointmentIds.push(car?.appointmentId);
        }
    });
    //isSlicedAppIds will be false on car-list in case we are fetching all the cars to show for listing.
    //we are fetching most recent 5 viewed cars(except car-list page) but there can be a case where we have max 15 appId's, to handle this case we are appending the other id's(5 to max) in our cookie again.  On listing page we are fetching all the cars, so we don't need to do this operation.
    if (isSlicedAppIds) {
        rvAppointmentIds.push(...recentlyViewedCars.slice(NUMBER.FIVE));
    }
    addToRecentlyViewed(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS, rvAppointmentIds);
};
