import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import HomeCarSection from "./component";

// Actions
import { fetchRecentlyAddedCars, fetchRecentlyViewedCars, fetchRecommendedCars, updateRecentlyViewedSSRStatus } from "../filters/actions";
import { clearAllFilters } from "../filters/actions";

const mapStateToProps = ({
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    filters: {
        recommendedCars,
        recentlyViewedCars,
        recentlyAddedCars,
        isRecommendedCarsLoading,
        isRecentlyViewedCarsLoading,
        isRecentlyAddedCarsLoading,
        isRecentlyViewedSSR
    }
}) => ({
    selectedCityName,
    selectedCityCode,
    recommendedCars,
    recentlyViewedCars,
    recentlyAddedCars,
    isRecommendedCarsLoading,
    isRecentlyViewedCarsLoading,
    isRecentlyAddedCarsLoading,
    isRecentlyViewedSSR,
    hasCitySkip
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRecommendedCarsConnect: fetchRecommendedCars,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars,
    fetchRecentlyAddedCarsConnect: fetchRecentlyAddedCars,
    clearAllFiltersConnect: clearAllFilters,
    updateRecentlyViewedSSRStatusConnect: updateRecentlyViewedSSRStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeCarSection);
