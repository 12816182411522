import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import HomeCarSection from "./component";

// Actions
import { fetchPopularCars, fetchRecentlyAddedCars, fetchRecentlyViewedCars, fetchRecommendedCars, updateRecentlyViewedSSRStatus } from "../filters/actions";
import { clearAllFilters } from "../filters/actions";
import { fetchWishlistedCarsList } from "../my-wishlist/actions";

const mapStateToProps = ({
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    user: {
        isLoggedIn
    },
    filters: {
        recommendedCars,
        popularCars,
        recentlyViewedCars,
        recentlyAddedCars,
        isRecommendedCarsLoading,
        isRecentlyViewedCarsLoading,
        isRecentlyAddedCarsLoading,
        isRecentlyViewedSSR,
        isPopularCarsSSR
}
}) => ({
    selectedCityName,
    selectedCityCode,
    recommendedCars,
    popularCars,
    recentlyViewedCars,
    recentlyAddedCars,
    isRecommendedCarsLoading,
    isRecentlyViewedCarsLoading,
    isRecentlyAddedCarsLoading,
    isRecentlyViewedSSR,
    hasCitySkip,
    isLoggedIn,
    isPopularCarsSSR
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRecommendedCarsConnect: fetchRecommendedCars,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars,
    fetchRecentlyAddedCarsConnect: fetchRecentlyAddedCars,
    fetchPopularCarsConnect: fetchPopularCars,
    clearAllFiltersConnect: clearAllFilters,
    updateRecentlyViewedSSRStatusConnect: updateRecentlyViewedSSRStatus,
    fetchWishlistedCarsListConnect: fetchWishlistedCarsList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeCarSection);
