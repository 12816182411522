const getAppliedFilter = (currentFilter, appliedFilters) => {
    const selectedFilter = appliedFilters.filter((appliedFilter) => {
        const isFilterKeyMatched = appliedFilter.filterKey === currentFilter.filterKey;
        const isOptionMatched = currentFilter.optionKey ? (currentFilter.optionKey === appliedFilter.optionKey) : true;
        const isSubOptionMatched = currentFilter.subOptionKey ? (currentFilter.subOptionKey === appliedFilter.subOptionKey) : true;
        return isFilterKeyMatched && isOptionMatched && isSubOptionMatched;
    })[0];

    return selectedFilter;
};

export default getAppliedFilter;
