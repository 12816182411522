
import { NUMBER } from "../../constants/app-constants";
import parseCookie from "./parse-cookie";
import saveCookie from "./save-cookie";
import { uniqueStack } from "./unique-stack";

export const getRecentlyViewed = (key, getLatest = false) => {
    const recentlyViewed = parseCookie(key);
    try {
        return recentlyViewed ? JSON.parse(recentlyViewed).slice(getLatest ? 0 : 1) : [];
    } catch (e) {
        return [];
    }
};

// eslint-disable-next-line no-magic-numbers
export const addToRecentlyViewed = (key, item, { max = NUMBER.FOURTEEN, expiry = 365 } = {}) => {
    const recentlyViewed = getRecentlyViewed(key, true);
    const updatedRecentlyViewed = uniqueStack(recentlyViewed, max + 1)(item);
    const slicedRecentlyViewed = (updatedRecentlyViewed || []).slice(0, max || NUMBER.FOURTEEN);
    saveCookie(key, JSON.stringify(slicedRecentlyViewed), expiry);
};

