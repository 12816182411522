import success from "./images/check.svg";
import failure from "./images/garbage.svg";
import exclaim from "./images/exclaim.svg";
import heart from "./images/orange-heart.svg";
import greenHeart from "./images/green-heart.svg";
import filter from "./images/filter.svg";

export const TOAST_VARIANT = {
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    EXCLAIM: "EXCLAIM",
    HEART: "HEART",
    GREEN_HEART: "GREEN_HEART",
    FILTER: "FILTER"
};

export const iconMap = {
    "SUCCESS": success,
    "FAILURE": failure,
    "EXCLAIM": exclaim,
    "HEART": heart,
    "GREEN_HEART": greenHeart,
    "FILTER": filter
};

export const TOAST_MESSAGE = {
    beneficiary: {
        added: "Beneficiary added successfully",
        additionFailed: "Failed to add beneficiary"
    },
    addedToWishlist: "Added to wishlist! You will be notified",
    removedFromWishlist: "Removed from wishlist!",
    feedbackSuccess: "Successfully submitted your feedback",
    fileSizeCaution: "File size should be below 10MB",
    downloadLinkSentSuccessfully: "Download link successfully sent",
    errorSendingDownloadLink: "Error while sending download link",
    errorUpload: "Re upload with the correct file size or doc",
    uploadSuccessful: (documentName = "Document") => `${documentName} Uploaded Successfully`,
    tinting: {
        added: "Tinting plan added successfully",
        removed: "Tinting plan removed successfully"
    },
    coating: {
        added: "Coating plan added successfully",
        removed: "Coating plan removed successfully"
    },
    warranty: {
        added: "Warranty plan added successfully",
        removed: "Warranty plan removed successfully"
    },
    refurbAndWarranty: {
        added: "Package added Successfully",
        removed: "Package removed Successfully"
    },
    insurance: {
        added: "Insurance plan added successfully",
        removed: "Insurance plan removed successfully"
    },
    bundle: {
        added: "Bundle plan added successfully",
        removed: "Bundle plan removed successfully"
    },
    serviceContract: {
        added: "Service contract added successfully",
        removed: "Service contract removed successfully"
    },
    gapInsurance: {
        added: "Gap insurance plan added successfully",
        removed: "Gap insurance plan removed successfully"
    },
    iloeInsurance: {
        added: "ILOE insurance plan added successfully",
        removed: "ILOE insurance plan removed successfully"
    },
    offers: {
        failed: "Please enter a coupon code"
    },
    alreadyRegisteredBanner: "We’ve already registered your interest",
    sellCarListingBanner: "We’ve registered your interest"
};
