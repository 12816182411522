import { AE_HOMEPAGE_PAGINATION_SIZE, NUMBER } from "../../../constants/app-constants";
import {FILTER_ENTITIES} from "../../../utils/filters-v2/constants";

export const defaultParams = {
    size: AE_HOMEPAGE_PAGINATION_SIZE,
    page: NUMBER.ZERO,
    variant: "filterV5"
};

export const defaultFilterPayload = {
    selectedFilters: [],
    filters: {}
};

export const minWidthFilters = {
    [FILTER_ENTITIES.MAKE]: "36px",
    [FILTER_ENTITIES.PRICE]: "44px",
    [FILTER_ENTITIES.YEAR]: "27px",
    [FILTER_ENTITIES.EMI]: "20px",
    [FILTER_ENTITIES.BODY_TYPE]: "62px",
    [FILTER_ENTITIES.ODOMETER_READING]: "48px"
};
