export const SOURCES = {
    MENU: "menu",
    WISHLIST: "wishlist",
    MY_BOOKINGS: "myBookings",
    SELL_ORDER: "sellOrder",
    FINANCING: "financing",
    START_PURCHASE: "startPurchase",
    ADD_TO_WISHLIST: "addToWishlist",
    COUPON_DISCOUNT: "COUPON_DISCOUNT",
    SELL_CAR_LISTING_BANNER: "SELL_CAR_LISTING_BANNER",
    MAKE_AN_OFFER: "makeAnOffer",
    SALE_SNACKBAR: "saleSnackbar",
    SALE_BANNER_HOME: "saleBannerHome",
    SALE_BANNER_LISTING: "saleBannerListing",
    DP_SALE_LINE: "dp_sale_line"
};
