import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../../ae.configuration/actions";

export default function withLogin(Component) {
    const AuthenticatedComponent = (props) => {
        const {
            isLoggedIn
        } = props;
        const [showLogin, setShowLogin] = useState(false);
        return (
            <Component
                {...props}
                isLoggedIn={isLoggedIn}
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />
        );
    };

    AuthenticatedComponent.propTypes = {
        isLoggedIn: PropTypes.bool
    };

    const mapStateToProps = ({ user: { isLoggedIn, secureToken, name } }) => {
        return {
            isLoggedIn,
            secureToken,
            name
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        logoutConnect: logout
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(
        AuthenticatedComponent
    );
}
