import React, { useState } from "react";
import PropTypes from "prop-types";
import VizSensor from "react-visibility-sensor";

const TrackableRecentlyAddedCarCardItem = ({ children, onVisibleCallback = () => { }, carId }) => {

    const [isViewed, setIsViewed] = useState(false);
    const onChangeCardVisibility = (isVisible) => {
        if (isVisible && !isViewed) {

            onVisibleCallback({appId: carId});
            setIsViewed(true);
        }
    };

    return (
        <VizSensor
            onChange={onChangeCardVisibility}
        >{children}</VizSensor>
    );
};

TrackableRecentlyAddedCarCardItem.propTypes = {
    children: PropTypes.object,
    onVisibleCallback: PropTypes.func,
    carId: PropTypes.any
};

export default TrackableRecentlyAddedCarCardItem;
