import React from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";
import Modal from "../../shared/modal";

const Login = loadable(() => import("../login"), { ssr: false });
import withLogin from "../with-login";

const LoginRevamp = ({isVisible, children, onSuccess, onClose, loginFor, isSaleLogin = false}) => {
  return (
    <React.Fragment>
            {children}
            {isVisible && <Modal isOpen={true}>
                <div style={{display: "flex", justifyContent: "center", height: "100vh", alignItems: "center"}}>
                   <Login isVisible={isVisible} isSaleLogin={isSaleLogin} onSuccess={onSuccess} onClose={onClose} loginFor={loginFor}/>
                </div>
            </Modal>}
        </React.Fragment>
  );
};

LoginRevamp.propTypes = {
    isVisible: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    loginFor: PropTypes.string,
    isSaleLogin: PropTypes.bool
};

export default LoginRevamp;
export { withLogin };
