import { COUNTRY_CODE } from "../../constants/app-constants";
import priceFormatter from "./price-formatter";

const makeOdometerLabel = (price, country = COUNTRY_CODE.IN) => `${priceFormatter(price, true, country)} km`;

export const makeOdometerLabelAE = (price) => {
    return makeOdometerLabel(Math.round(price), COUNTRY_CODE.AE);
};

export const makeOdometerLabelAU = (price) => {
    return makeOdometerLabel(Math.round(price), COUNTRY_CODE.AU);
};

export default makeOdometerLabel;
